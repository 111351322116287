<template>
  <header>
    <nav class="navbar top-bar d-none d-md-block">
      <div class="container-fluid px-lg-4 px-md-3">
        <ul class="navbar-nav mr-auto ml-md-0 ml-auto">
          <li class="nav-item slogan">
            <b>{{ $t("message.header.group") }}</b>
          </li>
        </ul>
        <ul class="navbar-nav navbar-expand-md">
          <li class="nav-item d-none d-md-block nav-button">
            <a
              class="nav-link"
              target="_blank"
              rel="noopener"
              href="https://www.lubrigaz.pt"
            >
              Volkswagen</a
            >
          </li>
          <li class="nav-item d-none d-md-block nav-button">
            <a
              class="nav-link"
              target="_blank"
              rel="noopener"
              href="https://skoda.lubrigaz.pt"
            >
              ŠKODA</a
            >
          </li>
          <li class="nav-item d-none d-md-block nav-button">
            <a
              class="nav-link"
              target="_blank"
              rel="noopener"
              href="https://www.lubrisport.pt"
            >
              Audi</a
            >
          </li>
          <li class="nav-item d-none d-md-block nav-button">
            <a
              class="nav-link"
              target="_blank"
              rel="noopener"
              href="https://comerciais.lubrigaz.pt"
            >
              VW VComerciais</a
            >
          </li>
          <li class="nav-item d-none d-md-block nav-button">
            <a
              class="nav-link"
              target="_blank"
              rel="noopener"
              href="https://www.ducatileiria.pt"
            >
              Ducati</a
            >
          </li>
          <li class="nav-item d-none d-md-block nav-button">
            <a
              class="nav-link"
              target="_blank"
              rel="noopener"
              href="https://lubrirent.pt"
            >
              Lubrirent</a
            >
          </li>
        </ul>
      </div>
    </nav>
    <div
      class="container-fluid px-lg-4 px-md-3 border-bottom bg-white"
      id="superfixed"
    >
      <div class="d-flex justify-content-center pb-2 pt-md-2 pt-2">
        <div class="navbar-header d-block d-lg-none">
          <div id="parentx">
            <vs-button
              @click="sidebarShow"
              class="button-menu d-block d-md-none"
              style="background: rgba(0, 0, 0, 0) !important"
            >
              <vs-icon icon="menu" style="font-size: 29px"></vs-icon>
            </vs-button>
            <div class="vs-sidebar-new vs-sidebar-hide" id="sidebar1">
              <ul>
                <li>
                  <router-link
                    :to="{ name: 'main', params: { lang: path } }"
                    @click.native="sidebarShow"
                  >
                    {{ $t("message.header.home") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ path: `/${path}/${$t('message.alugar')}` }"
                    @click.native="sidebarShow"
                  >
                    {{ $t("message.header.rent") }}
                  </router-link>
                </li>
                <!--                <li>-->
                <!--                  <a href="https://www.lubrigaz.pt/usados-volkswagen" target="_blank">{{-->
                <!--                    $t("message.header.used")-->
                <!--                  }}</a>-->
                <!--                </li>-->
                <li>
                  <router-link
                    :to="{ path: `/${path}/${$t('message.profissionais')}` }"
                    @click.native="sidebarShow"
                  >
                    {{ $t("message.header.services") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ path: `/${path}/${$t('message.sobre-nos')}` }"
                    @click.native="sidebarShow"
                  >
                    {{ $t("message.header.company") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ path: `/${path}/${$t('message.contactos')}` }"
                    @click.native="sidebarShow"
                  >
                    {{ $t("message.header.contacts") }}
                  </router-link>
                </li>
              </ul>
              <div class="sidebar-group">
                <div id="dropdown-3" v-bind:text="lang" style="padding: 16px">
                  <span to="/pt" @click="changeLanguage('pt')">
                    <span class="fi fi-pt"></span> PT
                  </span>
                  <span style="padding: 0 8px">|</span>
                  <span to="/en" @click="changeLanguage('en')">
                    <span class="fi fi-gb"></span> EN
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mr-3 ml-md-0 ml-5">
          <h1 class="logo_fix">
            <a href="/">
              <router-link
                class="nav-link d-lg-block d-none"
                :to="{ name: 'main', params: { lang: path } }"
              >
                <img
                  src="../assets/images/logo.png"
                  alt="Lubrirent_desktop"
                  class="logo_header"
                />
              </router-link>
              <router-link
                class="d-lg-none d-block"
                :to="{ name: 'main', params: { lang: path } }"
              >
                <img
                  src="../assets/images/logo.png"
                  height="40px"
                  alt="Lubrirent_mobile"
                  class="logo_header"
                />
              </router-link>
            </a>
          </h1>
        </div>
        <div class="d-flex mr-auto">
          <nav class="navbar navbar-expand-md navbar-dark bg-dark">
            <div class="container px-0">
              <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav mr-auto nav-fill w-100">
                  <!-- <li class="nav-item">
                <router-link
                  class="nav-link home-button"
                  :to="{ name: 'main', params: { lang: path } }"
                >
                  <i class="fas fa-home pt-1"></i>
                </router-link>
              </li> -->
                  <li class="nav-item">
                    <router-link
                      class="nav-link"
                      :to="{ path: `/${path}/${$t('message.alugar')}` }"
                    >
                      {{ $t("message.header.rent") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link
                      class="nav-link"
                      :to="{ path: `/${path}/${$t('message.profissionais')}` }"
                    >
                      {{ $t("message.header.services") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link
                      class="nav-link"
                      :to="{ path: `/${path}/${$t('message.sobre-nos')}` }"
                    >
                      {{ $t("message.header.company") }}
                    </router-link>
                  </li>
                  <!--              <li class="nav-item">-->
                  <!--                <a-->
                  <!--                  class="nav-link"-->
                  <!--                  href="https://www.lubrigaz.pt"-->
                  <!--                  target="_blank"-->
                  <!--                  >{{ $t("message.header.used") }}</a-->
                  <!--                >-->
                  <!--              </li>-->
                  <li class="nav-item">
                    <router-link
                      class="nav-link"
                      :to="{ name: 'contacts', params: { lang: path } }"
                    >
                      {{ $t("message.header.contacts") }}
                    </router-link>
                  </li>
                  <li class="d-none d-md-block">
                    <b-dropdown
                      id="dropdown-1"
                      v-bind:text="lang"
                      style="padding: 12px 8px 0"
                    >
                      <b-dropdown-item to="/pt" @click="changeLanguage('pt')">
                        <span class="fi fi-pt"></span> PT
                      </b-dropdown-item>
                      <b-dropdown-item to="/en" @click="changeLanguage('en')">
                        <span class="fi fi-gb"></span> EN
                      </b-dropdown-item>
                    </b-dropdown>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div class="d-flex justify-content-end pr-md-3 pr-0 pt-md-4 pt-0">
          <ul class="d-none d-md-block contact-header">
            <li class="mb-1"></li>
            <li>
              <div>
                <b-dropdown
                  id="dropdown-4"
                  :html="
                    '<i class=&quot;fas fa-phone&quot;></i>' +
                    $t('message.header.phones')
                  "
                  class="dropdown"
                >
                  <b-dropdown-item>
                    <a
                      href="tel:00351244830000"
                      onclick="dataLayer.push({
                                    event          : 'contact_click',
                                    category       : 'lead_phone',
                                    action         : 'header',
                                    label          : '+351244830000',
                                    value          : 0,
                                    });"
                    >
                      <i class="fas fa-phone"></i> +351 244 830 000
                    </a>
                    <p style="font-size: 0.75rem; font-weight: 400">
                      {{ $t("message.cost") }}
                    </p>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <a
                      href="tel:00351924114635"
                      onclick="dataLayer.push({
                                    event          : 'contact_click',
                                    category       : 'lead_phone',
                                    action         : 'header',
                                    label          : '+351924114635',
                                    value          : 0,
                                    });"
                    >
                      <i class="fas fa-phone"></i> +351 924 114 635
                    </a>
                    <p style="font-size: 0.75rem; font-weight: 400">
                      {{ $t("message.cost_mobile") }}
                    </p>
                  </b-dropdown-item>
                </b-dropdown>
                <a
                  href="https://wa.me/351924114635"
                  class="whatsapp-header"
                  target="_blank"
                  onclick="dataLayer.push({
                                    event          : 'whatsapp_click',
                                    category       : 'lead_whatsapp',
                                    action         : 'header',
                                    label          : '',
                                    value          : 0,
                                    });"
                >
                  <i class="fab fa-whatsapp"></i> WhatsApp
                </a>
              </div>
              <div class="dropdown show"></div>
            </li>
          </ul>
          <!-- Mobile -->
          <ul class="d-md-none contact-header">
            <li>
              <b-dropdown
                id="dropdown-4-mobile"
                :html="'<img src=&quot;https://vault13.rotauto.com/autosite/lubrigaz/vw/telefone.png&quot;/>'"
                class="dropdown"
              >
                <b-dropdown-item>
                  <a href="tel:00351244830000">
                    <i class="fas fa-phone"></i> +351 244 830 000
                  </a>
                  <p style="font-size: 0.75rem; font-weight: 400">
                    {{ $t("message.cost") }}
                  </p>
                </b-dropdown-item>
                <b-dropdown-item>
                  <a href="tel:00351924114635">
                    <i class="fas fa-phone"></i> +351 924 114 635
                  </a>
                  <p style="font-size: 0.75rem; font-weight: 400">
                    {{ $t("message.cost_mobile") }}
                  </p>
                </b-dropdown-item>
              </b-dropdown>
            </li>
            <li>
              <a
                href="https://wa.me/351924114635"
                class="whatsapp-header-mobile"
                target="_blank"
                onclick="dataLayer.push({
                                    event          : 'whatsapp_click',
                                    category       : 'lead_whatsapp',
                                    action         : 'header',
                                    label          : '',
                                    value          : 0,
                                    });"
              >
                <img src="../assets/icon/whatsapp.svg" alt="whatsapp-icon" />
              </a>
            </li>
          </ul>
        </div>
        <div class="d-flex justify-content-end pt-md-4 pt-0">
          <ul class="d-none d-md-block contact-header">
            <li class="mb-2"></li>
            <li>
              <a
                class="social-links mr-2"
                target="_blank"
                rel="noopener"
                href="facebook"
                ><i class="fab fa-facebook-square"></i
              ></a>
            </li>
            <li>
              <a
                class="social-links"
                target="_blank"
                rel="noopener"
                href="instagram"
                ><i class="fab fa-instagram"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import json from "../../website-data.json";
import { locales } from "../locales";
import i18n from "../plugins/i18n";

export default {
  name: "Header",
  components: {},
  props: {},
  data: function () {
    return {
      lang: this.$store.getters.setLocale,
      json: json,
      showMenu: true,
      drop: false,
      sidebarStatus: Boolean,
    };
  },
  computed: {
    path() {
      return this.$store.getters.setLocale;
    },
  },
  methods: {
    openDrop() {
      this.drop = true;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    setActive(val) {
      console.log(this.$router.history.current.name, val);
      return (val = true);
    },
    changeLanguage(e) {
      const lang = e;
      const to = this.$router.resolve({ params: { lang } });
      i18n.locale = lang;
      this.lang = lang;
      i18n.setLocaleMessage(lang, locales[lang]);
      this.$router.push(to.location).catch((err) => {});
      this.$store.dispatch("setLocalePath", { path: e });
      if (window.innerWidth < 768) {
        this.sidebarShow();
      }
    },
    sidebarShow() {
      this.sidebarStatus = this.sidebarStatus ? true : false;
      let sidebar = document.getElementById("sidebar1");
      if (this.sidebarStatus) {
        sidebar.classList.remove("vs-sidebar-hide");
        document.body.classList.add("overflow-hidden");
        this.sidebarStatus = false;
      } else if (!this.sidebarStatus) {
        sidebar.classList.add("vs-sidebar-hide");
        document.body.classList.remove("overflow-hidden");
        this.sidebarStatus = true;
      }
    },
  },
};
</script>
