import Vue       from 'vue';
import VueRouter from 'vue-router';
import {locales} from '../locales';
import tags      from '../locales/seo';
import {store}   from '../main';
import i18n      from './i18n';

Vue.use(VueRouter);

const ContactsPage        = () => import('../views/ContactsPage');
const CompanyPage         = () => import('../views/CompanyPage');
const MainPage            = () => import('../views/MainPage');
const PackPage            = () => import('../views/PackPage');
const RentPage            = () => import('../views/RentPage');
const ReservationFormPage = () => import('../views/ReservationFormPage');
const ServicesPage        = () => import('../views/ServicesPage');
const RentConditions      = () => import('../views/RentConditions');
const NotFound            = () => import('../views/NotFound');
const PrivacyPolicy       = () => import('../views/PrivacyPolicy');

// initial locale setting from the path locale prefix
const currentLocale = location.pathname.split('/')[1] || 'en';
i18n.locale         = currentLocale;

const getAlias = name => {
	const translation = i18n.t(`message.${name}`);
	return translation !== name ? translation : undefined;
};

const router = new VueRouter({
	mode   : 'history',
	base   : process.env.BASE_URL,
	routes : [
		{
			path      : '/404',
			component : NotFound,
		},
		{
			path      : '/',
			redirect  : '/pt',
			component : {
				template : '<router-view></router-view>'
			}
		},
		{
			path      : '/:lang',
			component : {
				template : '<router-view></router-view>'
			},
			meta      : {
				gtm : 'Main Page',
			},
			beforeEnter(to, from, next) {
				const lang = to.params.lang;
				if (!['en', 'pt'].includes(lang)) return next('404');
				if (i18n.locale === lang) {
					store.dispatch('setLocalePath', { path : lang });
					return next();
				}

				i18n.locale = lang;
				i18n.setLocaleMessage(lang, locales[lang]);
				store.dispatch('setLocalePath', { path : lang });
				return next();
			},
			children : [
				{
					path      : 'pre-reservation',
					name      : 'pre-reservation',
					component : ReservationFormPage,
					meta      : {
						gtm : 'Pre Reservation Page',
					},
				},
				{
					path      : locales['pt'].message.alugar,
					name      : 'rent',
					alias     : getAlias('alugar'),
					component : RentPage,
					meta      : {
						path : { en : locales['en'].message.alugar, pt : locales['pt'].message.alugar },
						gtm  : 'Rent Page',
					},
				},
				{
					path      : locales['pt'].message.contactos,
					name      : 'contacts',
					alias     : getAlias('contactos'),
					component : ContactsPage,
					meta      : {
						path : { en : locales['en'].message.contactos, pt : locales['pt'].message.contactos },
						gtm  : 'Contacts Page',
					},
				}, {
					path      : locales['pt'].message.profissionais,
					name      : 'pro-services',
					alias     : getAlias('profissionais'),
					component : ServicesPage,
					meta      : {
						path : { en : locales['en'].message.profissionais, pt : locales['pt'].message.profissionais },
						gtm  : 'Pro Services Page',
					},
				},
				{
					path      : locales['pt'].message['sobre-nos'],
					alias     : getAlias('sobre-nos'),
					name      : 'company',
					component : CompanyPage,
					meta      : {
						path : { en : locales['en'].message['sobre-nos'], pt : locales['pt'].message['sobre-nos'] },
						gtm  : 'Company Page',
					},
				},
				{
					path      : 'politica-privacidade',
					name      : 'politica-privacidade',
					component : PrivacyPolicy
				},
				{
					path      : 'condicoes-aluguer',
					name      : 'condicoes-aluguer',
					component : RentConditions
				},
				{
					path      : '',
					name      : 'main',
					component : MainPage,
				},
				{
					path      : ':pack_name',
					name      : 'vehicles',
					component : PackPage,
					meta      : {
						gtm : 'Pack Page',
					},
				}
			]
		},
		{
			path     : '*',
			redirect : { name : 'notfound' },
		},
	]
});

// function hasQueryParams(route) {
// 	return !!Object.keys(route.query).length;
// }
//
// router.beforeEach((to, from, next) => {
// 	if (!hasQueryParams(to) && hasQueryParams(from)) {
// 		let toWithQuery = Object.assign({}, to, { query : from.query });
// 		next(toWithQuery);
// 	} else {
// 		next();
// 	}
// });

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

	// Turn the meta tag definitions into actual elements in the head.
	let name = '';

	if (to.name === 'contacts' || to.name === 'services' || to.name === 'company' || to.name === 'rent') {
		name = to.name;
	} else {
		name = 'generic';
	}

	let lang = to.params.lang !== undefined ? to.params.lang : 'pt';
	tags[`seo_${lang}`][name].map(tagDef => {
			const tag = document.createElement('meta');

			Object.keys(tagDef).forEach(key => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create, so we don't interfere with other ones.
			tag.setAttribute('data-vue-router-controlled', '');

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach(tag => document.head.appendChild(tag));

	next();
});

router.afterEach(to => {
	const isLocalizable = router.currentRoute.meta.path;

	if (isLocalizable) {
		const localizedRoute = {};
		// constructing a new localized path using the previously created route meta data
		const routeSegments  = router.currentRoute.meta.path[i18n.locale];
		for (const key in to.params) {
			if (key !== 'locale') routeSegments.replace(`:${key}`, to.params[key]);
		}
		localizedRoute.path   = `/${i18n.locale}/${routeSegments}`;
		// appending query (if any)
		localizedRoute.query  = { ...router.currentRoute.query };
		localizedRoute.params = to.params;

		const hasUnresolvedPath = Object.values(to.params).some(el =>
			el.includes(':')
		);
		if (router.currentRoute.path !== localizedRoute.path && !hasUnresolvedPath)
			router.push(localizedRoute);
	}
});

export default router;
