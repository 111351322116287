<template>
	<footer class="footer">
		<div class="container pb-4">
			<div class="row">
				<div class="col-md-3 col-xs-12 pt-5">
					<ul class="footer-menu">
						<li>
							<!-- <i class="fas fa-home"></i> -->
							<router-link
								:to="{ name: 'main', params: { lang: path } }"
								@click.native="scrollToTop()"
							>
								{{ $t('message.header.home') }}
							</router-link>
						</li>
						<li>
							<!-- <i class="fas fa-car"></i> -->
							<router-link
								:to="{ path: `/${path}/${$t('message.alugar')}` }"
								@click.native="scrollToTop()"
							>
								{{ $t('message.header.rent') }}
							</router-link>
						</li>
						<li>
							<!-- <i class="fas fa-tools"></i> -->
							<router-link
								:to="{ path: `/${path}/${$t('message.profissionais')}` }"
								@click.native="scrollToTop()"
							>
								{{ $t('message.header.services') }}
							</router-link>
						</li>
						<li>
							<!-- <i class="fas fa-building"></i> -->
							<router-link
								:to="{ path: `/${path}/${$t('message.sobre-nos')}` }"
								@click.native="scrollToTop()"
							>
								{{ $t('message.header.company') }}
							</router-link>
						</li>
						<li>
							<!-- <i class="fas fa-car-alt"></i> -->
							<a href="https://www.lubrigaz.pt/usados-volkswagen" target="_blank">{{
									$t('message.header.used')
								}}</a>
						</li>
						<li>
							<!-- <i class="fas fa-phone-alt"></i> -->
							<router-link
								:to="{ path: `/${path}/${$t('message.contactos')}` }"
								@click.native="scrollToTop()"
							>
								{{ $t('message.header.contacts') }}
							</router-link>
						</li>
					</ul>
				</div>
				<div class="col-md-4 col-xs-12 pt-5">
					<div class="footer-contactos-icon">
						<i class="fas fa-home"></i>
					</div>
					<div class="footer-contactos-text">
						<h5>{{ $t('message.footer.address') }}</h5>
						<div v-for="address in addresses">
							<p style="margin-bottom: 0; font-weight: bold">
								{{ address.location }}
							</p>
							<a :href="'tel:'+address.contact[0]" @click="gtmClick(address.location)">
								<p>{{ address.contact[0] }}</p>
							</a>
							<p style="font-size:0.75rem;font-weight:400;margin-top:-15px;">{{ $t('message.cost') }}</p>
						</div>
					</div>
					<div class="footer-contactos-icon">
						<i class="far fa-envelope"></i>
					</div>
					<div class="footer-contactos-text">
						<h5>Email</h5>
						<a href="mailto:geral@lubrirent.pt" onclick="dataLayer.push({
                                    event          : 'contact_click',
                                    category       : 'lead_email',
                                    action         : 'footer',
                                    label          : '',
                                    value          : 0,
                                    });">geral@lubrirent.pt</a>
					</div>
				</div>
				<div class="col-md-5 col-xs-12 pt-5">
					<div class="footer-contactos-icon">
						<i class="far fa-clock fa-lg iconColor"></i>
					</div>
					<div class="footer-contactos-text">
						<h5>{{ $t('message.footer.schedule') }}</h5>
						<p class="mb-0">
							<b>{{ $t('message.footer.dias-uteis') }}</b> <br/>
							{{ $t('message.footer.horario1') }}
						</p>
						<br/>
						<!-- <p class="mb-0">
						  <b>{{ $t("message.footer.saturday") }}</b> <br />
						  {{ $t("message.footer.horario2") }}
						</p> -->
					</div>
				</div>
			</div>
			<div class="row mt-5 pt-4 border-top">
				<div class="col-12">
					<div class="social-media text-center">
						<h4>{{ $t('message.footer.followus') }}</h4>
						<a
							class="social-links mr-2"
							target="_blank"
							rel="noopener"
							href="facebook"
						><i class="fab fa-facebook-square"></i
						></a>
						<a
							class="social-links"
							target="_blank"
							rel="noopener"
							href="instagram"
						><i class="fab fa-instagram"></i
						></a>
					</div>
					<div class="policies text-center mt-5">
						<a v-bind:href="'/' + lang + '/condicoes-aluguer'">{{
								$t('message.form.terms')
							}}</a>
						|
						<a v-bind:href="'/' + lang + '/politica-privacidade'">
							{{ $t('message.form.policies') }}</a>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid sub-footer">
			<cookie-law theme="mytheme">
				<div
					slot-scope="props"
					style="margin: 0 auto; padding: 15px 15px 0 15px"
				>
					<p style="line-height: 2rem">
						{{ $t('message.cookies.text') }}
						<a
							style="text-decoration: underline"
							v-bind:href="'/' + lang + '/politica-privacidade'"
							target="_blank"
						>{{ $t('message.form.policies') }}</a
						>
						<button
							class="ml-3 btn btn-primary"
							@click="props.accept"
							style="font-size: 14px; font-weight: 700"
						>
							<span>{{ $t('message.cookies.accept') }}</span>
						</button>
					</p>
				</div>
			</cookie-law>
			<div class="container">
				<div class="row">
					<div class="col-12 d-md-flex justify-content-md-between">
						<div class="d-md-flex justify-content-md-around">
							<div>
								&copy; {{ year }}
								<span class="footer-yellow"
								><a target="_blank" href="https://lubrirent.com"
								>Lubrirent</a
								></span
								>
							</div>
							<div class="pl-1 pr-1 d-none d-md-block">|</div>
							<div>
								{{ $t('message.tax') }}
							</div>
						</div>
						<div class="pt-3 pt-md-0">
							<a href="https://rotauto.pro/" target="_blank">{{
									$t('message.developed')
								}}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import * as moment from 'moment';
import CookieLaw   from 'vue-cookie-law';
import {version}   from '../../package.json';
import json        from '../../website-data.json';

export default {
	name       : 'Footer',
	components : { CookieLaw },
	props      : {},
	data       : function () {
		return {
			lang      : this.$store.getters.setLocale,
			year      : moment().year(),
			version   : version,
			addresses : json.pick_ups,
		};
	},
	methods    : {
		scrollToTop() {
			window.scrollTo(0, 0);
		},
		gtmClick(location) {
			if (this.$gtm.enabled()) {
				window.dataLayer?.push({
					event          : 'contact_click',
					category       : 'lead_phone',
					action         : 'footer',
					label          : location,
					value          : 0,
					noninteraction : false // Optional
				});
			}
		}
	},
	computed   : {
		path() {
			return this.$store.getters.setLocale;
		},
	},
};
</script>
