export default {
	'seo_pt' : {
		generic  : [
			{
				title : 'Lubrirent'
			},
			{
				name    : 'description',
				content : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'Lubrirent'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://Lubrirent.com'
			},
			{
				property : 'og:description',
				content  : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'Lubrirent'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		homepage : [
			{
				title : 'Lubrirent'
			},
			{
				name    : 'description',
				content : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'Lubrirent'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://Lubrirent.com'
			},
			{
				property : 'og:description',
				content  : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'Lubrirent'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		rent     : [
			{
				title : 'Lubrirent | Aluguer de viaturas'
			},
			{
				name    : 'description',
				content : 'Aluguer de viaturas em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'Lubrirent'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://Lubrirent.com'
			},
			{
				property : 'og:description',
				content  : 'Aluguer de viaturas em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'Lubrirent'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		services : [
			{
				title : 'Lubrirent | Serviços'
			},
			{
				name    : 'description',
				content : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'Lubrirent'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://Lubrirent.com'
			},
			{
				property : 'og:description',
				content  : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'Lubrirent'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		company  : [
			{
				title : 'Lubrirent | A Empresa'
			},
			{
				name    : 'description',
				content : 'Conheça a Lubrirent. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'Lubrirent | A Empresa'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://Lubrirent.com'
			},
			{
				property : 'og:description',
				content  : 'Conheça a Lubrirent. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'Lubrirent'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		contacts : [
			{
				title : 'Lubrirent | Contactos'
			},
			{
				name    : 'description',
				content : 'Contactos da Lubrirent. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'Lubrirent | Contactos'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://Lubrirent.com'
			},
			{
				property : 'og:description',
				content  : 'Contactos da Lubrirent. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'Lubrirent'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		]
	},
	'seo_en' : {
		generic  : [
			{
				title : 'Lubrirent'
			},
			{
				name    : 'description',
				content : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'Lubrirent'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://Lubrirent.com'
			},
			{
				property : 'og:description',
				content  : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'Lubrirent'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		homepage : [
			{
				title : 'Lubrirent'
			},
			{
				name    : 'description',
				content : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'Lubrirent'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://Lubrirent.com'
			},
			{
				property : 'og:description',
				content  : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'Lubrirent'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		rent     : [
			{
				title : 'Lubrirent | Aluguer de viaturas'
			},
			{
				name    : 'description',
				content : 'Aluguer de viaturas em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'Lubrirent'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://Lubrirent.com'
			},
			{
				property : 'og:description',
				content  : 'Aluguer de viaturas em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'Lubrirent'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		services : [
			{
				title : 'Lubrirent | Serviços'
			},
			{
				name    : 'description',
				content : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'Lubrirent'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://Lubrirent.com'
			},
			{
				property : 'og:description',
				content  : 'A sua rent-a-car de eleição em Leiria e Fátima. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'Lubrirent'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		company  : [
			{
				title : 'Lubrirent | A Empresa'
			},
			{
				name    : 'description',
				content : 'Conheça a Lubrirent. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'Lubrirent | A Empresa'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://Lubrirent.com'
			},
			{
				property : 'og:description',
				content  : 'Conheça a Lubrirent. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'Lubrirent'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		],
		contacts : [
			{
				title : 'Lubrirent | Contactos'
			},
			{
				name    : 'description',
				content : 'Contactos da Lubrirent. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				name    : 'image',
				content : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:title',
				content  : 'Lubrirent | Contactos'
			},
			{
				property : 'og:type',
				content  : 'website'
			},
			{
				property : 'og:url',
				content  : 'https://Lubrirent.com'
			},
			{
				property : 'og:description',
				content  : 'Contactos da Lubrirent. Temos várias viaturas disponíveis, desde desportivos a transporte de passageiros, aos melhores preços.'
			},
			{
				property : 'og:site_name',
				content  : 'Lubrirent'
			},
			{
				property : 'og:locale',
				content  : 'pt_PT'
			},
			{
				property : 'og:image',
				content  : 'http://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:secure_url',
				content  : 'https://vault13.rotauto.com/sites/Lubrirent/Lubrirent-rent-a-car-og.jpg'
			},
			{
				property : 'og:image:width',
				content  : '1299'
			},
			{
				property : 'og:image:height',
				content  : '727'
			},
			{
				property : 'fb:admins',
				content  : '41231972906307'
			}
		]
	}
};
